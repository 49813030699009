.group:before,
.group:after {
  content: '';
  display: table;
}
.group:after {
  clear: both;
}
.group {
  clear: both;
  *zoom: 1;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

a:hover {
  cursor: pointer;
}

.swal2-container {
  z-index: 100000 !important;
}

/* .tox .tox-form__group {
  display: none;
} */

/* .tox div.tox-dialog__body-nav div:first-child {
  display: none;
} */
