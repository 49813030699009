@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
.group:before,
.group:after {
  content: '';
  display: table;
}
.group:after {
  clear: both;
}
.group {
  clear: both;
  *zoom: 1;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

a:hover {
  cursor: pointer;
}

.swal2-container {
  z-index: 100000 !important;
}

/* .tox .tox-form__group {
  display: none;
} */

/* .tox div.tox-dialog__body-nav div:first-child {
  display: none;
} */

:root {
  --white: #fff;
  --oh-orange: rgba(242, 94, 53, 1);
  --oh-black: rgba(0, 0, 8, 0.8);
  --oh-light-gray: #f7f8fa;
  --oh-primary-black: rgba(0, 0, 0, 0.86);
  --oh-secondary-black: rgba(0, 0, 8, 0.5);

  --oh-white: #f5f5f5;
  --oh-off-white: #f8f8f8; /* for payment card */
  --oh-black-light: #717171;
  --oh-blue: #66b5de;
  --oh-teal: #95c48c;
  --oh-disabled: #d3d3d3;
  --oh-red: #f08d88;
  --oh-green: #a3c996;
  --oh-vl-blue: rgba(102, 181, 222, 0.1);
  --oh-border-gray: rgba(0, 0, 0, 0.05);
  --oh-shadow-gray: rgba(64, 64, 64, 0.3);
  --oh-box-gray: #fafafa;

  --oh-brand-1: rgba(242, 183, 5, 1);
  --oh-brand-vl-1: rgba(242, 183, 5, 0.1);
  --oh-brand-2: rgba(140, 86, 55, 1);
  --oh-brand-vl-2: rgba(140, 86, 55, 0.1);
  --oh-brand-3: rgba(132, 108, 217, 1);
  --oh-brand-vl-3: rgba(132, 108, 217, 0.1);
  --oh-brand-4: rgba(191, 99, 105, 1);
  --oh-brand-vl-4: rgba(191, 99, 105, 0.1);
  --oh-brand-5: rgba(147, 204, 88, 1);
  --oh-brand-vl-5: rgba(147, 204, 88, 0.1);
  --oh-brand-6: rgba(94, 174, 1, 1);
  --oh-brand-vl-6: rgba(94, 174, 1, 0.1);
  --oh-brand-7: rgba(11, 158, 217, 1);
  --oh-brand-vl-7: rgba(11, 158, 217, 0.1);
  --oh-brand-8: rgba(255, 118, 107, 1);
  --oh-brand-vl-8: rgba(255, 118, 107, 0.1);
  --oh-brand-9: rgba(68, 137, 171, 1);
  --oh-brand-vl-9: rgba(68, 137, 171, 0.1);
}

@font-face {
  font-family: Avenir;
  src: local('Avenir Next'), url(/static/media/AvenirReg.cfe4d361.ttf);
  font-display: swap;
}
@font-face {
  font-family: AvenirDemi;
  src: local('Avenir Next'), url(/static/media/AvenirDemi.04f89874.ttf);
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Nunito Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: unset;
  word-break: break-word;
}

.MathJax_Display {
  margin: unset !important;
}
/* Scrollbar customizations currently only for webkit engine */

/* ::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */

/* Scrollbar customizations */

*,
*::before,
*::after {
  box-sizing: border-box !important;
}

